import React, { useState, useEffect } from "react";
import bg from "../assets/networkgif.webp"
import bg2 from "../assets/networkgif2.gif"
import gplay from "../assets/gplay.png"
import Header from "../components/header";
import Footer from "../components/footer";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import {Autocomplete} from "@mui/joy"
import {db, auth} from "../App"
import { collection, getDocs, doc, getDoc, setDoc, query, where } from "firebase/firestore";
import {Auth, createUserWithEmailAndPassword } from "firebase/auth"
import { Search } from "@mui/icons-material";
import { width } from "@mui/system";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import GoogleButton from 'react-google-button'
import lofi from "../assets/lofi.jpg"
import lofi2 from "../assets/lofi1.png"
import lofi7 from "../assets/lofi7.jpg"



function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}



const SignUp: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [move_1, set_next] = useState<boolean>(false)
  const [username, set_username] = useState<string>("")
  const [email, set_email] = useState<string>("")
  const [interests, set_interests] = useState<any>([])
  const [pin, set_pin] = useState<string>("")
  const [city, set_city] = useState<string>("")
  const [country, set_country] = useState<string>("")
  const [lat, set_lat] = useState<string>("")
  const [lng, set_long] = useState<string>("")
  const [password, set_password] = useState<string>("")
  const [is_popup,set_is_popup] = useState<boolean>(false) //TBA in edit mode
  const [popup_message, set_popup_message] = useState<string>("");
  const [loading_box,set_is_loading] = useState<boolean>(false) //TBA in edit mode
  const [loading_message, set_loading_message] = useState<string>("");
  const [profession, set_profession] = useState<string>("");
  const location = useLocation();

  const navigate = useNavigate();

  const [userType, setUserType] = useState<string>("Space Traveller")

  const handleChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value as string);
  };

  useEffect(() => {

    // console.log("type is ", location.state.type)
    // //fetchPost();
    // let prof = localStorage.getItem("profession");
    // console.log('prx',prof)

    // if(prof!= null){
    //   set_profession(location.state.type)
    // }
    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


async function fetchPost(){
       
    await getDocs(collection(db, "users"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            //console.log('dxx',querySnapshot.docs)
        })
   
}

function validateEmail(email:any) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function hasWhiteSpace(s:any) {

    if (/\s/.test(s)) {
      return true
    }
    else{

      return false
    }

  }


function go_Next(){

  console.log('px',pin)
  if(pin !== "" && !hasWhiteSpace(pin) && pin !== null && pin !== undefined){
    //set_next(!move_1)
    getFromPin()
  }
}


function getFromPin(){

  var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + pin +'&key=AIzaSyANC3y2BFY8f7eKsR7dHlADmXBsBdRisw0';
  
  console.log('getting address')
  fetch(url)
      .then((response) => response.json())
      .then((response) => {

          console.log("RESP LOX " + JSON.stringify(response, null, 2));

          if(response.status !== "ZERO_RESULTS"){

              for(var i = 0; i < response.results.length; i++){
              
                  for(var k = 0; k < response.results[i].address_components.length; k++){


                      if(response.results[i].address_components[k].types.includes("sublocality_level_1")){

                        var local = response.results[i].address_components[k].long_name;

                        //context.setState({MyLocal:local});

                        //console.log("locality s " + local)

                    }

                      if(response.results[i].address_components[k].types.includes("sublocality")){

                          var local = response.results[i].address_components[k].long_name;

                          //context.setState({MyLocality:local});

                          //console.log("locality is " + local)

                      }

                      if(response.results[i].address_components[k].types.includes("locality")){

                          var city = response.results[i].address_components[k].long_name;

                         // context.setState({MyCity:city});

                          //console.log("city is " + city)
                          set_city(city)

                      }

                      if(response.results[i].address_components[k].types.includes("country")){

                          var country = response.results[i].address_components[k].long_name;

                          //context.setState({MyCountry:country});

                          console.log("country is " + country)
                          set_country(country)

                      }

                  }

                  console.log('geo geo ' + JSON.stringify(response.results[i].geometry.location.lat))
                  set_lat(response.results[i].geometry.location.lat)
                  set_long(response.results[i].geometry.location.lng)

                 // context.setState({latitude:response.results[i].geometry.location.lat})
                  //context.setState({longitude:response.results[i].geometry.location.lng})
                 // context.setState({results:"not-none"})
                 confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div style={{backgroundColor:"#dadada", margin:10, padding:20}}>
                        <h3 style={{color:"black"}}>Confirm Location</h3>
                        <p style={{color:"black"}}>Your location is: {city} , {country}</p>
                        <button style={{marginLeft:5, marginRight:5}} onClick={onClose}>No</button>
                        <button style={{marginLeft:5, marginRight:5}}
                          onClick={() => {
                            register()
                            onClose();
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    );
                  }
                });
                 

          }

          }
          else{
              console.log('no results')

              //context.setState({results:"none"})
          }

  }).then(()=>{
    
      
  })
}

function password_validator(input:string){


    let regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$")
    
    let test = regex.test(input);

    if(test === true){

      if (hasWhiteSpace(input)=== true){

        return false;
      }
      else{
        return true;
      }

    }
    else{

      return test;
    }

  }


const register = async()=>{


    if(validateEmail(email) === false){

        set_is_popup(true)
        set_popup_message("Please enter a valid email.")
  
        setTimeout(() => {
  
          set_is_popup(false)
          set_popup_message("Error.")
  
          
        }, 2000);

    }
    else if(username.length < 4){

        set_is_popup(true)
        set_popup_message("Username must be atleast 4 characters.")
  
        setTimeout(() => {
  
          set_is_popup(false)
          set_popup_message("Error.")
  
          
        }, 2000);


    }
    else if(password_validator(password) === false || password.length < 8){

        set_is_popup(true)
        set_popup_message("Passwords must contain one lowercase, one uppercase, one special character and minimum 8 character length.")
  
        setTimeout(() => {
  
          set_is_popup(false)
          set_popup_message("Error.")
  
          
        }, 2000);

    }
    else if(hasWhiteSpace(email) === true|| hasWhiteSpace(password) === true ||hasWhiteSpace(username) === true){

        set_is_popup(true)
        set_popup_message("No blank spaces allowed.")
  
        setTimeout(() => {
  
          set_is_popup(false)
          set_popup_message("Error.")
  
          
        }, 2000);

    }
    else{

        const docRef = doc(db, "usernames", username);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        console.log('username exists')
        set_is_popup(true)
        set_popup_message("The username is already taken. Please try another.")
  
        setTimeout(() => {
  
          set_is_popup(false)
          set_popup_message("Error.")
  
          
        }, 2000);
        } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");

        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;
                console.log('user',user)

                var rand = String(Math.floor(Math.random()*9000) + 1000);

                var timeNow = new Date().getTime();
                var userId =  rand + timeNow;

                await setDoc(doc(collection(db, "users"), userId), {
                    username: username,
                    userId:userId,
                    email:email.trim(),
                    userpic:"",
                    profession:"",
                    latitude:lat,
                    longitude:lng,
                    city:city,
                    country:country,
                    location:city+", "+country,
                    bio:"",
                    address:"",
                    accounttype:"Personal",
                    businesstype:"",
                    businessname:"",
                    hits:0,
                    interests:interests,
                    joindate:timeNow
                })

                await setDoc(doc(collection(db, "usernames"), username), {
                    username: username
                })
                .then(()=>{
                    console.log('dx','done)')
                    set_is_popup(true)
                    set_popup_message("Registration complete. Please login...")
              
                    setTimeout(() => {

                      navigate("/login")
                      set_is_popup(false)
                      set_popup_message("Error.")
                      
                    }, 2000);
                })
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                console.log('error',error)
                set_is_popup(true)
                set_popup_message("The email address is already taken. Please try another.")
          
                setTimeout(() => {
          
                  set_is_popup(false)
                  set_popup_message("Error.")
          
                  
                }, 2000);

        });

        }


    }

    console.log('registerx')


}

const googleSignin = () => {

if(userType === ""){
  alert("Please select your primary skill!")
  return;
}

const auth = getAuth();
const context = this;

const provider = new GoogleAuthProvider();
signInWithPopup(auth, provider)
  .then(async(result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    if (credential != null) {
    const token = credential.accessToken;
    }
    // The signed-in user info.
    const user = result.user;
    // IdP data available using getAdditionalUserInfo(result)
    // console.log('signed in',user)
    // localStorage.setItem('user',JSON.stringify(user))
    const userjson = JSON.parse(JSON.stringify(user))
    let validate = false;
    var rand = String(Math.floor(Math.random()*9000) + 1000);

    var timeNow = new Date().getTime();
    var userId =  rand + timeNow;

    const q = query(collection(db, "website_users"), where("email", "==", userjson.email));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // localStorage.setItem("user", JSON.stringify(doc.data()))
      // validate = true
    });

    if(validate === false){
      await setDoc(doc(collection(db, "website_users"), userjson.uid), {
          username: userjson.displayName,
          userId:userjson.uid,
          email:userjson.email,
          userpic:"https://firebasestorage.googleapis.com/v0/b/kaamka-dotcom.appspot.com/o/profile_images%2Fprofile-user.png?alt=media&token=045c43a5-ddb6-4251-b94b-c0b3866b2643",
          profession:userType,
          skills:null,
          bio:"",
          location:"",
          joindate:timeNow
      }).then(async ()=>{

        alert("We'll email you a link to download the app once it's ready!")

        setTimeout(() => {
          navigate("/")
      }, 2000);
      })
   }
   else{

    alert("We'll email you a link to download the app once it's ready!")
    navigate("/")

   }



    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log('errorx',error)
    // ...
  });
}



async function googleFullsignIn(){
  console.log('signing in...');

  const auth = getAuth();

  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
  .then(async(result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    if (credential != null) {
    const token = credential.accessToken;
    }
    // The signed-in user info.
    const user = result.user;
    // IdP data available using getAdditionalUserInfo(result)
    console.log('signed in',user)
    // localStorage.setItem('user',JSON.stringify(user))
    const userjson = JSON.parse(JSON.stringify(user))
    let validate = false;
    var rand = String(Math.floor(Math.random()*9000) + 1000);
    var rand2 = String(Math.floor(Math.random()*900000) + 10000);

    var timeNow = new Date().getTime();
    var userId =  rand + timeNow;

    const q = query(collection(db, "contactList"), where("email", "==", userjson.email));


    let firstName = userjson.displayName.split(" ")[0]
    let lastName = userjson.displayName.split(" ")[1]

    if (firstName === undefined){
      firstName = "User"
    }

    if (lastName === undefined){
              lastName = String(Math.floor(Math.random()*9000) + 1000);
          }

    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
      // localStorage.setItem("user", JSON.stringify(doc.data()))
      validate = true
    })

    if(querySnapshot.size > 0){

      const docRef = doc(db, "users",userjson.uid);

      const docSnap = await getDoc(docRef);

      console.log('dtttxt',docSnap.data())

      localStorage.setItem("user",JSON.stringify(docSnap.data()))
      localStorage.setItem("email",userjson.email)


      navigate("/")
      return false;

    }
    else{

      await setDoc(doc(collection(db, "contactList"), userjson.uid), {
        username: userjson.displayName,
        userId:userjson.uid,
        email:userjson.email,
        userpic:"https://firebasestorage.googleapis.com/v0/b/kaamka-dotcom.appspot.com/o/profile_images%2Fprofile-user.png?alt=media&token=045c43a5-ddb6-4251-b94b-c0b3866b2643",
        profession:"new",
        skills:null,
        privateName:"",
        bio:"",
        location:"",
        joindate:timeNow
      }).then(async ()=>{
  
        await setDoc(doc(collection(db, "users"), userjson.uid), {
          userId:userjson.uid,
          uid:userjson.uid,
          email:userjson.uid,
          username:firstName + rand2,
          firstName:firstName,
          lastName:lastName,
          userpic:"",
          profession:"",
          interests:"",
          hobbies:"",
          tvshows:"",
          writers:"",
          movies:"",
          games:"",
          music:"",
          website:"",
          location:"",
          books:"",
          others:"",
          latitude:"",
          longitude:"",
          gender:"",
          myLocal:"",
          myLocality:"",
          bio:"",
          birthplace:"",
          occupation:"",
          address:"",
          accounttype:"",
          businesstype:"",
          businessname:"",
          hits:0,
        }).then(async ()=>{

          const docRef = doc(db, "users",userjson.uid);

          const docSnap = await getDoc(docRef);

          console.log('dtttt',docSnap.data())

          localStorage.setItem("user",JSON.stringify(docSnap.data()))
          localStorage.setItem("email",userjson.email)

          navigate("/profile")

          return false;

        })

    })

    }
    
  })


}



 if(window.innerWidth > 650){
  return (


    <div style={{margin:0, backgroundSize:"cover",  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:10, paddingLeft:200, paddingRight:200,paddingBottom:200}} >

        <img src={logo} style={{height:100 ,borderRadius:20, margin:20, marginBottom:50}}/>


        {/* <TextField placeholder="Search for skillsets...." onChange={(event)=>set_search(event.target.value)} style={{width:500, maxWidth:500, backgroundColor:'white', borderRadius:2}} /> */}

        {/* <p style={{ color:'white', marginTop:50}}>Select Your Profession</p> */}
    <div>
    

    <div style={{display:'flex',flexDirection: 'column',justifyContent:'center',alignItems:'center'}}>
    {/* <p style={{textAlign:'left', float:'left', color:'white', alignSelf:'flex-start', marginTop:2, fontFamily:'Montserrat'}}>Your Phone Number</p>
    <TextField onChange={(event)=>set_email(event.target.value)}  placeholder="" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} /> */}
    {/* <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10, fontFamily:'Montserrat'}}>Username</p>
    <TextField onChange={(event)=>set_username(event.target.value)} placeholder="Create Your Username" style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:5}} />
    <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10, fontFamily:'Montserrat'}}>Password</p>
    <TextField onChange={(event)=>set_password(event.target.value)} placeholder="Create Your Password" style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:5}} />
     */}
    {/* <Box sx={{ minWidth: 120 }}>
    <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">Your Primary Skill</InputLabel>
    <Select
        style={{width:290,color:'black'}}
        value={userType}
        label="Skill"
        color="primary"
        onChange={handleChange}
      >
    <MenuItem value={"Musician"}>Musician</MenuItem>
    <MenuItem value={"Artist"}>Artist</MenuItem>
    <MenuItem value={"Programmer"}>Programmer</MenuItem>
    <MenuItem value={"Writer"}>Writer</MenuItem>
    <MenuItem value={"Actor"}>Actor</MenuItem>
    </Select>
    </FormControl>
    </Box> */}

    <h3 style={{padding:20}}>Awesome ! Sign In to start achieving all your dreams.</h3>
    <div style={{marginTop:20}}>
    <GoogleButton onClick={()=>googleFullsignIn()}  style={{paddingLeft:20, width:290, marginLeft:5, paddingRight:20, color:'black', height:50, fontStyle:'bold', fontWeight:400, fontSize:16, backgroundColor:"white"}}>Sign In With Google</GoogleButton>
    </div>
    <p style={{margin:20,marginLeft:50,marginRight:50,width:290, marginTop:40, textAlign:'center'}}>By signing in, you agree to our terms and conditions and privacy policy. Click here for all details.</p>
    </div>
    </div>
    </div>

    

);


 }
 else{

  return (



    <div style={{margin:0,backgroundSize:"cover",backgroundPosition:'center',maxWidth:'100%', width:'100%',  flexDirection:'column', backgroundColor:'white', zIndex:99999, height:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:2, paddingRight:2,paddingTop:40}} >

        <img src={logo} style={{height:100 ,borderRadius:20, margin:20, marginBottom:50}}/>

        {/* <p style={{marginTop:-40, marginBottom:40, fontStyle:'italic', color:'white'}}>Aap kya karte hain ?</p> */}

        {/* <TextField placeholder="Search for skillsets...." onChange={(event)=>set_search(event.target.value)} style={{width:500, maxWidth:500, backgroundColor:'white', borderRadius:2}} /> */}

        {/* <p style={{ color:'white', marginTop:50}}>Select Your Profession</p> */}
        <div>
    

    <div style={{display:'flex',flexDirection: 'column',justifyContent:'center',alignItems:'center'}}>
    {/* <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:2, fontFamily:'Montserrat'}}>Email</p>
    <TextField onChange={(event)=>set_email(event.target.value)}  placeholder="Your Email Address" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
    <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10, fontFamily:'Montserrat'}}>Username</p>
    <TextField onChange={(event)=>set_username(event.target.value)} placeholder="Create Your Username" style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:5}} />
    <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10, fontFamily:'Montserrat'}}>Password</p>
    <TextField onChange={(event)=>set_password(event.target.value)} placeholder="Create Your Password" style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:5}} />
     */}
    <div style={{marginTop:60}}>
    <GoogleButton onClick={()=>googleFullsignIn()}  style={{paddingLeft:20, width:290, marginLeft:5, paddingRight:20, color:'black', height:50, fontStyle:'bold', fontWeight:400, fontSize:16, backgroundColor:"white"}}>Join Kaamka.Com</GoogleButton>
    </div>
    </div>
    </div>
    </div>


);
 }

  
};

export default SignUp;
