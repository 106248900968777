import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField,Autocomplete, Chip } from "@mui/material";
import {db, auth} from "../App"
import { collection, getDocs, doc, getDoc, setDoc, query, where, updateDoc } from "firebase/firestore";
import {createUserWithEmailAndPassword } from "firebase/auth"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";



function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

interface skills {
    id: number;
    name: string;
  }


const skillList: skills[] = [
    { id: 1, name: "Guitar" },
    { id: 2, name: "Drums" },
    { id: 3, name: "Bass" },
    { id: 4, name: "Keyboard" },
    { id: 5, name: "Production" },
  ];
  


const CreateProfile: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [move_1, set_next] = useState<boolean>(false)
  const [username, set_username] = useState<string>("")
  const [email, set_email] = useState<string>("")
  const [interests, set_interests] = useState<any>([])
  const [value, setValue] = React.useState<any>([skillList[0].name]);
  const [pin, set_pin] = useState<string>("")
  const [city, set_city] = useState<string>("")
  const [country, set_country] = useState<string>("")
  const [lat, set_lat] = useState<string>("")
  const [lng, set_long] = useState<string>("")
  const [password, set_password] = useState<string>("")
  const [is_popup,set_is_popup] = useState<boolean>(false) //TBA in edit mode
  const [popup_message, set_popup_message] = useState<string>("");
  const [loading_box,set_is_loading] = useState<boolean>(false) //TBA in edit mode
  const [loading_message, set_loading_message] = useState<string>("");
  const [profession, set_profession] = useState<string>("");
  const [user,set_user] = useState<any>(null)
  const [bio,set_bio] = useState<any>(null)
  const [skills,set_skills] = useState<any>(null)
  const [loc,set_loc] = useState<any>(null)
  const [my_skills,set_myskills] = useState<any>([])


  const location = useLocation();

  const navigate = useNavigate();

  const [userType, setUserType] = useState<string>("Space Traveller")

  const handleChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value as string);
  };

  useEffect(() => {
    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    getProfile()

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[]);


  async function getProfile(){

    let userL = localStorage.getItem('user');

    if(userL != null){
      console.log('udss',JSON.parse(userL));
      let usx = JSON.parse(userL)

      await set_user(JSON.parse(userL));

    }
  }


async function fetchPost(){
       
    await getDocs(collection(db, "users"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            //console.log('dxx',querySnapshot.docs)
        })
   
}

function validateEmail(email:any) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function hasWhiteSpace(s:any) {

    if (/\s/.test(s)) {
      return true
    }
    else{

      return false
    }

  }


function go_Next(){

  console.log('px',pin)
  if(pin !== "" && !hasWhiteSpace(pin) && pin !== null && pin !== undefined){
    //set_next(!move_1)
    getFromPin()
  }
}


function getFromPin(){

  var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + pin +'&key=AIzaSyANC3y2BFY8f7eKsR7dHlADmXBsBdRisw0';
  
  console.log('getting address')
  fetch(url)
      .then((response) => response.json())
      .then((response) => {

          console.log("RESP LOX " + JSON.stringify(response, null, 2));

          if(response.status !== "ZERO_RESULTS"){

              for(var i = 0; i < response.results.length; i++){
              
                  for(var k = 0; k < response.results[i].address_components.length; k++){


                      if(response.results[i].address_components[k].types.includes("sublocality_level_1")){

                        var local = response.results[i].address_components[k].long_name;

                        //context.setState({MyLocal:local});

                        //console.log("locality s " + local)

                    }

                      if(response.results[i].address_components[k].types.includes("sublocality")){

                          var local = response.results[i].address_components[k].long_name;

                          //context.setState({MyLocality:local});

                          //console.log("locality is " + local)

                      }

                      if(response.results[i].address_components[k].types.includes("locality")){

                          var city = response.results[i].address_components[k].long_name;

                         // context.setState({MyCity:city});

                          //console.log("city is " + city)
                          set_city(city)

                      }

                      if(response.results[i].address_components[k].types.includes("country")){

                          var country = response.results[i].address_components[k].long_name;

                          //context.setState({MyCountry:country});

                          console.log("country is " + country)
                          set_country(country)

                      }

                  }

                  console.log('geo geo ' + JSON.stringify(response.results[i].geometry.location.lat))
                  set_lat(response.results[i].geometry.location.lat)
                  set_long(response.results[i].geometry.location.lng)

                 // context.setState({latitude:response.results[i].geometry.location.lat})
                  //context.setState({longitude:response.results[i].geometry.location.lng})
                 // context.setState({results:"not-none"})
                 confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div style={{backgroundColor:"#dadada", margin:10, padding:20}}>
                        <h3 style={{color:"black"}}>Confirm Location</h3>
                        <p style={{color:"black"}}>Your location is: {city} , {country}</p>
                        <button style={{marginLeft:5, marginRight:5}} onClick={onClose}>No</button>
                        <button style={{marginLeft:5, marginRight:5}}
                          onClick={() => {
                            register()
                            onClose();
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    );
                  }
                });
                 

          }

          }
          else{
              console.log('no results')

              //context.setState({results:"none"})
          }

  }).then(()=>{
    
      
  })
}

function password_validator(input:string){


    let regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$")
    
    let test = regex.test(input);

    if(test === true){

      if (hasWhiteSpace(input)=== true){

        return false;
      }
      else{
        return true;
      }

    }
    else{

      return test;
    }

  }


const register = async()=>{


    if(validateEmail(email) === false){

        set_is_popup(true)
        set_popup_message("Please enter a valid email.")
  
        setTimeout(() => {
  
          set_is_popup(false)
          set_popup_message("Error.")
  
          
        }, 2000);

    }
    else if(username.length < 4){

        set_is_popup(true)
        set_popup_message("Username must be atleast 4 characters.")
  
        setTimeout(() => {
  
          set_is_popup(false)
          set_popup_message("Error.")
  
          
        }, 2000);


    }
    else if(password_validator(password) === false || password.length < 8){

        set_is_popup(true)
        set_popup_message("Passwords must contain one lowercase, one uppercase, one special character and minimum 8 character length.")
  
        setTimeout(() => {
  
          set_is_popup(false)
          set_popup_message("Error.")
  
          
        }, 2000);

    }
    else if(hasWhiteSpace(email) === true|| hasWhiteSpace(password) === true ||hasWhiteSpace(username) === true){

        set_is_popup(true)
        set_popup_message("No blank spaces allowed.")
  
        setTimeout(() => {
  
          set_is_popup(false)
          set_popup_message("Error.")
  
          
        }, 2000);

    }
    else{

        const docRef = doc(db, "usernames", username);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        console.log('username exists')
        set_is_popup(true)
        set_popup_message("The username is already taken. Please try another.")
  
        setTimeout(() => {
  
          set_is_popup(false)
          set_popup_message("Error.")
  
          
        }, 2000);
        } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");

        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;
                console.log('user',user)

                var rand = String(Math.floor(Math.random()*9000) + 1000);

                var timeNow = new Date().getTime();
                var userId =  rand + timeNow;

                await setDoc(doc(collection(db, "users"), userId), {
                    username: username,
                    userId:userId,
                    email:email.trim(),
                    userpic:"",
                    profession:"",
                    latitude:lat,
                    longitude:lng,
                    city:city,
                    country:country,
                    location:city+", "+country,
                    bio:"",
                    address:"",
                    accounttype:"Personal",
                    businesstype:"",
                    businessname:"",
                    hits:0,
                    interests:interests,
                    joindate:timeNow
                })

                await setDoc(doc(collection(db, "usernames"), username), {
                    username: username
                })
                .then(()=>{
                    console.log('dx','done)')
                    set_is_popup(true)
                    set_popup_message("Registration complete. Please login...")
              
                    setTimeout(() => {

                      navigate("/login")
                      set_is_popup(false)
                      set_popup_message("Error.")
                      
                    }, 2000);
                })
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                console.log('error',error)
                set_is_popup(true)
                set_popup_message("The email address is already taken. Please try another.")
          
                setTimeout(() => {
          
                  set_is_popup(false)
                  set_popup_message("Error.")
          
                  
                }, 2000);

        });

        }


    }

    console.log('registerx')


}



async function saveProfile(){

    console.log('saveProfile', user)

    if(username.length < 4){

      alert("Username must be atleast 4 characters.")

      return;


    }

    if(loc.length < 4){

      alert("Please choose a location.")


      return;


    }

    if(my_skills.length < 1){

      alert("Choose atleast one skill")

      return;

    }

    const userRef = doc(db, "users",user.userId);

      await updateDoc(userRef, {
        skills:my_skills,
        location:loc,
        username:username
      }).then(()=>{
        navigate("/feedList")
      })

  }

  async function getSkills(){
    skillList.map((data) => {
      return (
        <h2>{data.name}</h2>
      )
    })
  }

  async function addSkill(skill:any){

    console.log('addSkill',skill, my_skills)
    let update = [...my_skills]
    if(update.includes(skill)){
      var index = update.indexOf(skill);
      if (index !== -1) {
        update.splice(index, 1);
      }
    }
    else{
      update.push(skill)
    }

    set_myskills(update)

  }

 if(window.innerWidth > 650){
  return (


    <div style={{margin:0, backgroundSize:"cover",  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:10, paddingLeft:200, paddingRight:200,paddingBottom:200}} >


    <div style={{display:'flex',justifyContent:'center',alignItems:'center', flexDirection:"column", borderColor:'#dadada',borderWidth:1, borderStyle:'solid'}}>
    
    <img src={logo} style={{height:100 ,borderRadius:20, margin:20, marginBottom:10}}/>

    <p>Badhe kaam ka hai !</p>

    <div style={{display:'flex',flexDirection: 'column',justifyContent:'center',alignItems:'center'}}>

    <p style={{textAlign:"left", alignSelf:'flex-start', margin:10, marginLeft:20}}>Create Your Username</p>

    <TextField placeholder="Create your username." onChange={(event)=>set_username(event.target.value)} style={{width:'90%', margin:5, maxWidth:'90%', backgroundColor:'white', borderRadius:2}} />

    <p style={{textAlign:"left", alignSelf:'flex-start', margin:10, marginLeft:20}}>Enter Your Location</p>

    <TextField placeholder="Enter your location." onChange={(event)=>set_loc(event.target.value)} style={{width:'90%', margin:5, maxWidth:'90%', backgroundColor:'white', borderRadius:2}} />

    <p style={{textAlign:"left", alignSelf:'flex-start', margin:10, marginLeft:20}}>Choose Your Skills</p>

    <div style={{backgroundColor:'white', maxWidth:340, padding:10}}>
    {skillList.map((data) => {
      return (
        my_skills.includes(data.name)?
        <Button onClick={()=>addSkill(data.name)} style={{color:'red'}}>{data.name}</Button>
        :
        <Button onClick={()=>addSkill(data.name)} >{data.name}</Button>
      )
    })}    
    </div>
    {/* <div style={{backgroundColor:'white'}}>
                    <Autocomplete
                      value={value}
                      style={{backgroundColor:'white',}}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      multiple
                      id="tags-filled"
                      options={skillList.map((option) => option.name)}
                      freeSolo
                      renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            style={{backgroundColor:'white', maxWidth:200}}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Skills"
                          style={{backgroundColor:"white", width:350}}
                          placeholder="Search"
                        />
                      )}
                    />
                </div> */}


    <div style={{marginTop:20}}>
        <Button onClick={()=>saveProfile()} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', marginTop:20, height:40, width:'80%', borderRadius:20, fontSize:12}}>Save</Button>
    </div>
    <p style={{margin:20,marginLeft:50,marginRight:50,width:290, marginTop:40, textAlign:'center'}}>By signing in, you agree to our terms and conditions and privacy policy. Click here for all details.</p>
    </div>
    </div>
    </div>

    

);


 }
 else{

  return (



    <div style={{margin:0,backgroundSize:"cover",backgroundPosition:'center',maxWidth:windowSize.innerWidth, width:windowSize.innerWidth,  flexDirection:'column', backgroundColor:'white', zIndex:99999, height:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center',paddingTop:40}} >

    <div style={{display:'flex', width:windowSize.innerWidth,justifyContent:'center',alignItems:'center', flexDirection:"column", borderColor:'#dadada',borderWidth:1, borderStyle:'solid'}}>
    
    <img src={logo} style={{height:100 ,borderRadius:20, margin:20, marginBottom:10}}/>

    <p>Badhe kaam ka hai !</p>

    <div style={{display:'flex',flexDirection: 'column',justifyContent:'center',alignItems:'center'}}>

    <TextField placeholder="Create your username." onChange={(event)=>set_username(event.target.value)} style={{width:'90%', margin:5, maxWidth:'90%', backgroundColor:'white', borderRadius:2}} />

    <TextField placeholder="Enter your location." onChange={(event)=>set_loc(event.target.value)} style={{width:'90%', margin:5, maxWidth:'90%', backgroundColor:'white', borderRadius:2}} />

    <div style={{backgroundColor:'white', maxWidth:340, padding:10}}>
    {skillList.map((data) => {
      return (
        my_skills.includes(data.name)?
        <Button onClick={()=>addSkill(data.name)} style={{color:'red'}}>{data.name}</Button>
        :
        <Button onClick={()=>addSkill(data.name)} >{data.name}</Button>
      )
    })}    
    </div>

          {/* <div style={{backgroundColor:'white'}}>
                <Autocomplete
                  value={value}
                  style={{backgroundColor:'white',}}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  multiple
                  id="tags-filled"
                  options={skillList.map((option) => option.name)}
                  freeSolo
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        style={{backgroundColor:'white', maxWidth:200}}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Skills"
                      style={{backgroundColor:"white", width:350}}
                      placeholder="Search"
                    />
                  )}
                />
            </div> */}


    <div style={{marginTop:20}}>
        <Button onClick={()=>saveProfile()} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', marginTop:20, height:40, width:'80%', borderRadius:20, fontSize:12}}>Save</Button>
    </div>
    <p style={{margin:20,marginLeft:50,marginRight:50,width:290, marginTop:40, textAlign:'center'}}>By signing in, you agree to our terms and conditions and privacy policy. Click here for all details.</p>
    </div>
    </div>
    </div>


);
 }

  
};

export default CreateProfile;
