import React, { useState, useEffect } from "react";
import bg from "../assets/networkgif.webp"
import bg2 from "../assets/networkgif2.gif"
import gplay from "../assets/gplay.png"
import Header from "../components/header";
import Footer from "../components/footer";
import { Button, TextField } from "@mui/material";
import {Autocomplete} from "@mui/joy"
import {db, auth} from "../App"
import { collection, getDocs, doc, getDoc, setDoc } from "firebase/firestore";
import {Auth, getAuth, createUserWithEmailAndPassword } from "firebase/auth"
import { Search } from "@mui/icons-material";
import { width } from "@mui/system";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import lofi from "../assets/lofi.jpg"
import lofi2 from "../assets/lofi2.webp"
import lofi7 from "../assets/lofi7.jpg"
import lofi8 from "../assets/lofi8.png"


function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}



const ViewProfile: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const loc = useLocation();
  const postURL = loc.pathname.split('/'); 
  console.log('URLis', postURL);
  const profileID = postURL[2]
  const navigate = useNavigate();
  const [user,set_user] = useState<any>(null)
  const [main_user,set_main_user] = useState<any>(null)
  const [bio,set_bio] = useState<any>(null)
  const [skills,set_skills] = useState<any>(null)
  const [value, setValue] = React.useState<any>(null);
  const [profileSkills, setProfileSkills] = React.useState<any>(null);
  const [location,set_location] = useState<any>(null)
  const [image, set_image] = useState<any>(null)
  const [isImgSelected, set_isImgSelected] = useState<any>(false)
  const [profession,set_profession] = useState<any>(null)

  useEffect(() => {

    //fetchPost();

    getProfile()

    getViewProfile();

    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[]);


  async function getViewProfile(){

    const docRef = doc(db, "users",profileID);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      
      await set_user((docSnap.data()));
      

      if(docSnap.data().bio != null && docSnap.data().bio != undefined){
        console.log('got biox', docSnap.data().bio)
        await set_bio(docSnap.data().bio);
      }
      if(docSnap.data().skills!= null && docSnap.data().skills!= undefined){
        await setValue(docSnap.data().skills);
      }
      if(docSnap.data().location!= null && docSnap.data().location!= undefined){
        await set_location(docSnap.data().location);
      }

    }
    }


  async function getProfile(){

    let userL = localStorage.getItem('user');
    let profession = localStorage.getItem('profession');

    if(userL != null){
      console.log('udss',JSON.parse(userL));
      let usx = JSON.parse(userL)

      await set_user(JSON.parse(userL));
      await set_profession(usx.profession);

    const docRef = doc(db, "users",usx.userId);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      
      await set_main_user((docSnap.data()));
    

    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    }

  }



if(user!= null && main_user!= null){
 if(window.innerWidth > 650){
  return (


    <div style={{margin:0,  backgroundImage:"url("+lofi8+")",backgroundSize:"cover",  flexDirection:'column', backgroundColor:'white', zIndex:99999, height:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:200, paddingRight:200,paddingTop:40}} >

        <img src={logo} style={{height:40 ,borderRadius:20, margin:20, marginBottom:50}}/>

        {/* <p style={{marginTop:-40, marginBottom:40, fontStyle:'italic', color:'white'}}>Aap kya karte hain ?</p> */}

        <div style={{display:'flex',flexDirection:'row', height:200, width:'60%', justifyContent:'center',borderRadius:50, alignItems:'center'}}>

            <div style={{flex:1,borderBottomLeftRadius:10,borderTopLeftRadius:10, backgroundColor:"#280137", justifyContent:'center',alignItems:'center', display:'flex', height:'100%', margin:0}}>

              {user != null?
              <img src={user.userpic} style={{height:90,width:90}}/>
              // <p style={{fontSize:28,backgroundColor:"black",color:'white',padding:50,borderRadius:40}}>{(user.username).substring(0, 1)}</p>
              :
              null}
              
            </div>

            <div style={{flex:1,borderBottomRightRadius:10,borderTopRightRadius:10, flexDirection:'column', backgroundColor:"#280137", height:'100%', margin:0,justifyContent:'center',alignItems:'center', display:'flex',}}>

                <h3 style={{color:'white'}}>{user.username}</h3>

                <h2 style={{textAlign:'center',color:'white'}}>{profession}</h2>

                {user.userId === main_user.userId
                ?
                <Button onClick={()=>navigate("/editprofile")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Edit Profile</Button>
                :
                <p style={{color:"white"}}>{user.bio}</p>
                }

            </div>

        </div>

        <div style={{display:'flex',backgroundColor:"#280137", flexDirection:'column', marginTop:40, height:300, width:'60%', justifyContent:'center',borderRadius:10, alignItems:'center'}}>


                <p style={{color:'white'}}>You have not uploaded anything yet. </p>

                <div style={{margin:10}}>
                <Button onClick={()=>navigate("/upload")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Share Your Work</Button>

                </div>

        </div>

        <div>
    
    </div>
    </div>

    );
 }
 else{

  return (

    <div style={{margin:0,backgroundImage:"url("+lofi8+")",backgroundSize:"cover",backgroundPosition:'center',maxWidth:'100%', width:'100%',  flexDirection:'column', backgroundColor:'white', zIndex:99999, height:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:2, paddingRight:2,paddingTop:40}} >

        <img src={logo} style={{height:40 ,borderRadius:20, margin:20, marginBottom:50}}/>


        <div style={{display:'flex',flexDirection:'row',maxWidth:windowSize.innerWidth, height:200, width:'90%', justifyContent:'center',borderRadius:50, alignItems:'center'}}>

            <div style={{flex:1,borderBottomLeftRadius:10,borderTopLeftRadius:10, backgroundColor:"#280137", justifyContent:'center',alignItems:'center', display:'flex', height:'100%', margin:0}}>

              {user != null?
              <img src={user.photoURL?user.photoURL:null} style={{height:90,width:90}}/>
              :
              null}
              

            </div>

            <div style={{flex:1,borderBottomRightRadius:10,borderTopRightRadius:10, flexDirection:'column', backgroundColor:"#280137", height:'100%', margin:0,justifyContent:'center',alignItems:'center', display:'flex',}}>

                <h3 style={{color:"white"}}>{user.displayName}</h3>

                <h2 style={{textAlign:'center', color:"white", fontSize:12}}>{profession}</h2>

                {user.profile === undefined
                ?
                <Button variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:40, width:'80%', borderRadius:20, fontSize:10}}>Create Profile</Button>
                :
                <p>Profile</p>
                }

            </div>
            

        </div>


        <div style={{display:'flex',backgroundColor:"#280137", flexDirection:'column', marginTop:40, height:300, width:'90%', justifyContent:'center',borderRadius:10, alignItems:'center'}}>


                <p style={{color:"white"}}>You have not uploaded anything yet. </p>

                <div style={{margin:10}}>
                <Button variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Post A Lookout</Button>

                </div>

        </div>
        <div>
    

    <div style={{display:'flex',flexDirection: 'column',justifyContent:'center',alignItems:'center'}}>

    <div style={{marginTop:60}}>
    </div>
    </div>
    </div>
    </div>


);
 }
}
else{
  return null;
}

  
};

export default ViewProfile;
